// import config from 'appConfig.json'
import { element, object } from 'prop-types'
import { set } from 'react-ga'
import icons from 'utils/svgIcons'
import { getLayersAndSector } from 'utils/apiConfig'

let config = null

const mergeConfig = (internalConfig, layers, categories) => {
  const result = {
    ...internalConfig, layers, categories, sources: {}
  }
  layers.forEach((e) => {
    const layerName = e[Object.keys(e)].title
    result.sources[`${layerName}_source`] = {
      type: 'raster',
      tiles: [
        `${internalConfig.urlAPI}wms?service=WMS&bbox={bbox-epsg-3857}&version=1.1.0&request=GetMap&layers=${layerName}&transparent=true&width=256&height=256&srs=EPSG:3857&styles=&format=image/png&Tiled=true&`
      ],
      tileSize: 256
    }
    result.styles[`${layerName}_style`] = {
      id: `${layerName}_layer`,
      source: `${layerName}_source`,
      type: 'raster',
      paint: {}
    }
  })
  return result
}
const loadAppConfig = async () => {
  if (config !== null) {
    return
  }
  const url = process.env.REACT_APP_URL_CONFIG
  const configSources = await Promise.all([
    fetch(url).then((data) => data.json())
  ])

  const layersAndSector = await getLayersAndSector()
  const [internalConfig, layers, categories] = [configSources[0], layersAndSector.layers, layersAndSector.sector[0].categories]
  config = mergeConfig(internalConfig, layers, categories)
}

const getCategories = () => {
  const categoriesOrdenadas = config.categories.map((category) => ({
    id: category.id,
    title: category.title, // config.subCategories[id].title,
    path: icons.find((i) => i.id === category.title)?.path,
    color: icons.find((i) => i.id === category.title)?.color,
    subcategories: category.subcategories
  }))
  return categoriesOrdenadas
}

const getLayer = (id, subCategory) => {
  const layerAux = { ...config?.layers.find((e) => parseInt(Object.keys(e)) === id) }
  const layer = layerAux[id]
  layer.sourceId = `${layerAux[id].title}_source`
  layer.source = config?.sources[`${layerAux[id].title}_source`]
  layer.style = config?.styles[`${layerAux[id].title}_style`]
  layer.id = id
  layer.subTitle = subCategory
  layer.urlDownload = `${config.urlAPI}ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${layerAux[id].title}&outputFormat=SHAPE-ZIP`
  layer.link = `https://geoportal.tresdefebrero.gob.ar/layers/${layerAux[id].title}`

  getCategories().forEach((element) => {
    if (element.id) layer.color = element.color
  })
  return layer
}

const getLayerName = (id) => config?.layers
  .find((e) => parseInt(Object.keys(e)) === id)[id].title

const getSubCategory = (id) => {
  const subCategories = []
  const subcategoriesKeys = config?.categories.find((e) => e.title === id)?.subcategories ?? {}
  Object.keys(subcategoriesKeys).forEach((index) => {
    const { title, layersIds } = subcategoriesKeys[index]

    const layersAux = layersIds?.map((layerId) => ({ layerId, ...getLayer(layerId, title) })) ?? []
    const layers = layersAux.sort((a, b) => (a.title > b.title ? 1 : -1))

    subCategories.push({
      category: id,
      layers,
      title
    })
  })
  return subCategories
}

// TODO: eliminar los que métodos sin utilizar
// Métodos que devuelven mucha data y puede no ser serializable
const getFullLayerConfig = (idGroup, idLayer) => config.layersGroup
  .find((g) => g.id === idGroup)
  .layers.find((l) => l.id === idLayer)

// Métodos que retornan data acotada y segura de serializar

const getLayersGroups = () => config.layersGroup?.map(({ id, title }) => ({
  id,
  title
})) ?? []

const getLayersByLayersGroupId = (layersGroupId) => config.layersGroup
  .find((l) => l.id === layersGroupId)
  .layers.map(({
    id, title, color, index, info, link, reference
  }) => ({
    id,
    title,
    color,
    index,
    info,
    link,
    reference
  }))

const getSubcategoriesLayers = () => {
  const subCategoriLayers = []
  getCategories().forEach((element) => {
    subCategoriLayers.push({
      layers: element.subcategories[0].layersIds,
      title: element.subcategories[0].title,
      color: element.color,
      activas: 0
    })
  })
  return subCategoriLayers
}

const getCategoryTitle = (idCategory) => config.categories.find(({ title }) => title === idCategory).title

const getParcel3D = () => config.parcelLayers.edif_3D

const getApiUrl = () => config.urlAPI

const getParcelLayer = () => config.parcelLayers

const getBaseLayers = () => config.baseLayers

const getCustomsIcons = () => config.customIcons

const getInformation = () => config.information

const getSectionInfo = (idTitle) => config.information.find(({ id }) => id === idTitle)

export {
  getLayerName,
  getSubcategoriesLayers,
  getLayer,
  getSubCategory,
  loadAppConfig,
  getCategories,
  getFullLayerConfig,
  getLayersGroups,
  getLayersByLayersGroupId,
  getCategoryTitle,
  getParcel3D,
  getApiUrl,
  getParcelLayer,
  getBaseLayers,
  getCustomsIcons,
  getInformation,
  getSectionInfo
}
